<template>
    <div class="bg-white rounded-5 p-5">
        <ValidationObserver ref="form">
            <form>
                <div class="modal-list-third-party-app w-100">

                    <!-- RSS Detail Container -->
                    <div class="mx-2 my-2 mb-4">
                        <h1 class="page-heading" style="font-size: 1.5rem;">
                            {{ isEditable ? 'Feed Details' : 'Create New Feed' }}
                        </h1>
                        <div class="my-2 mx-2 d-flex w-100" style="gap:5%">
                            <Input label="Feed Title" labelClass="d-flex add-new-app-label" type="text"
                                class="w-30 field-container d-flex mt-2 mb-4 flex-column" name="title"
                                placeholder="Enter Feed Title" v-model="rssFeedDetails.feedtitle" />
                        </div>
                    </div>

                    <!-- Feed Item Container -->
                    <div class="modal-list-third-party-app w-100" style="margin-bottom:50px">
                        <h1 class="px-2 page-heading">{{ isFeedEditable ? 'Update Feed Item' : 'Add New Feed Item' }}
                        </h1>
                        <div class="mx-2 my-2 flex-column">
                            <div class="d-flex no-wrap my-2 w-100" style="gap: 5%;">
                                <Input label="Sub Feed Title" labelClass="d-flex add-new-app-label" type="text"
                                    class="w-30 field-container d-flex mx-2 mt-2 mb-4 flex-column" name="title"
                                    placeholder="Enter Sub Feed Title" v-model="feedItem.title" />
                                <div
                                    class="app-img-upload block field-container d-flex mb-2 mx-2 mt-2 mb-4 w-30 flex-column">
                                    <label class="add-new-app-label w-80">Icon/Image <span
                                            style="color:#999;font-weight: normal;">(Optional)</span></label>
                                    <div class="d-flex align-items-center">
                                        <button type="button" class="no-bg text-button btn-add-new-app w-80"
                                            style="margin-right: 3px; width: 20rem" @click="chooseFile">
                                            Choose file
                                        </button>
                                        <input id="fileid" type="file" name="upload" ref="upload" size="100000"
                                            @change="uploadFile($event, 'RssItemImage')" accept=" .jpg, .webp, .png"
                                            hidden />
                                        <div
                                            class="img-container-small d-flex justify-content-center align-items-center">
                                            <img 
                                                v-if="!feedItem.image && !showImgLoader"
                                                src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                            />
                                            <img v-if="feedItem.image && !showImgLoader" :src="feedItem.image"
                                                alt="image not uploaded" />
                                            <div class="d-flex flex-column" v-if="showImgLoader"
                                                style="margin-top: 15px">
                                                <div
                                                    class="d-flex justify-content-center align-items-center img-loader-main">
                                                    <b-spinner variant="primary" class="img-loader"></b-spinner>
                                                </div>
                                                <span class="img-loader-text">Uploading...</span>
                                            </div>
                                            <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                                cssClass="customtooltip" position="top">
                                                <span id="target" class="material-symbols-outlined img-delete-small"
                                                    role="button" @click="feedItem.image = ''">
                                                    delete
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <Input label="Link" labelClass="d-flex add-new-app-label" type="text"
                                    class="w-30 field-container d-flex mt-2 mb-4 flex-column" name="link"
                                    placeholder="Enter Link" v-model="feedItem.link" />
                            </div>

                            <div class="field-container d-flex mt-2 mx-2 mb-4 w-100" style="flex-direction:column">
                                <label for="description" class="me-3 d-flex add-new-app-label">Description</label>
                                <span class="field-container d-flex align-items-center mt-2 mb-4 w-100">
                                    <textarea placeholder="Enter Description" name="description" id="description"
                                        cols="100" rows="3" class="input-text w-100"
                                        style="border-radius: 8px !important" v-model="feedItem.description" />
                                </span>
                            </div>

                            <!-- RSS item bottom bar -->
                            <div class="d-flex my-4" style="min-height: 50px;">
                                <div class="d-flex px-5 w-30 align-items-center"
                                    style="background: rgb(241, 247, 255);border-radius:20px;border: 1px solid #ebeff4">
                                    <label style="flex:1;font-size:1.2rem;color: black;" for="sorting">
                                        Set feed item order
                                    </label>
                                    <span class='d-flex' style="flex:1">
                                        NEWEST
                                        <Toggle id="sorting" class="toggle-switch" v-model="rssFeedDetails.sorting"
                                            :labelClass="'switch'" @input="changeSorting" :spanClass="'slider'" />
                                        OLDEST
                                    </span>
                                </div>
                                <div class="d-flex justify-content-end w-70">
                                    <button v-if="isFeedEditable" type="button" class="no-bg btn-cancel"
                                        @click="resetFeedItem">Cancel</button>
                                    <button v-if="showImgLoader" class="save-btn" type="button">
                                        {{ isFeedEditable ? 'Update' : 'Add' }}
                                    </button>
                                    <button v-else class="save-btn" type="button" @click="saveRssItem">
                                        {{ isFeedEditable ? 'Update' : 'Add' }}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- RSS Item List -->
                    <div class="table-container">
                        <div class="d-flex justify-content-between table-header"
                            style="background: rgb(241, 247, 255);border-radius: 27px 27px 0 0;">
                            <h1 class="px-5 py-3 page-heading">Feed Item List</h1>
                        </div>
                        <div v-if="feedItemList && feedItemList.length"
                            class="rss-list-table profile-listing-body px-2 pb-2">
                            <b-table id="my-table" :items="feedItemList.slice()" :fields="fields" bordered responsive>
                                <template #cell(s_no)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template #cell(title)="row">
                                    <b-tooltip :target="`${row.index} title`" :title="row.item.title"
                                        triggers="hover" />
                                    <span :id="`${row.index} title`">{{ row.item.title }}</span>
                                </template>
                                <template #cell(publicationDate)="row">
                                    {{ formattedDate(row.item.publicationDate) }}
                                </template>
                                <template #cell(action)="row">
                                    <div class="d-flex justify-content-center align-items-center" style="gap: 20px">
                                        <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target"
                                            cssClass="customtooltip">
                                            <img src="@/assets/px/edit-icon.png" alt="" style="height: 20px" id="target"
                                                @click="editFeedItem(row.item)" />
                                        </ejs-tooltip>
                                        <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                            cssClass="customtooltip">
                                            <img src="@/assets/px/delete-icon.png" alt="" style="height: 20px"
                                                id="target" @click="showDeleteAlertPopUp(row.item)" />
                                        </ejs-tooltip>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div v-else class="fs-4 text-bolder d-flex align-items-center justify-content-center"
                            style="min-height:200px">
                            No items created
                        </div>
                    </div>
                </div>

                <!-- Main Bottom Bar -->
                <div class="mx-2">
                    <div class="py-5 d-flex justify-content-end">
                        <button v-if="isEditable" class="no-bg text-button btn-cancel px-4" @click="cancelEdit"
                            type="button">Cancel</button>
                        <button class="save-btn" @click="saveRecord" type="button">Save</button>
                    </div>
                </div>
            </form>
        </ValidationObserver>

        <div class="profile-delete-modal">
            <DeleteRSSModal v-if="showDeleteAlert" :open="showDeleteAlert" @cancel="showDeleteAlert = false"
                @handleDelete="handleDelete">
            </DeleteRSSModal>
        </div>

        <Loading v-if="loading" />

    </div>
</template>
<script>

import DeleteRSSModal from './delete_rss_modal';
import Loading from '@/components/general/loading/loading.vue';
import { dashboardBuilder, rssFeeds } from '../../util/apiRequests';
import { ValidationObserver } from 'vee-validate';
import { rssBuildNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
import dayjs from '@/util/dayjs';

const initialRssFeed = {
    feedtitle: '',
    feedurl: '',
    feedimage: '',
    sorting: true,
};

const initialFeedItem = {
    id: '',
    title: '',
    description: '',
    link: '',
    image: '',
    pubDate: '',
    publicationDate: '',
}

export default {
    name: "RSSBuilder",
    components: {
        Loading,
        DeleteRSSModal,
        ValidationObserver
    },
    data() {
        return {
            rssFeedDetails: { ...initialRssFeed },
            feedItem: { ...initialFeedItem },
            initialFeedDetails: null,
            feedItemList: [],
            loading: true,
            isEditable: false,
            showDeleteAlert: false,
            showImgLoader: false,
            feedItemIndex: null,
            isFeedEditable: false,
            fields: [
                { key: 's_no', label: 'S NO.', thStyle: { "padding-left": '0 !important' }, class: "table-col" },
                { key: 'title', label: 'Feed Title', class: "table-col" },
                { key: 'publicationDate', label: 'Publication Date & Time', class: "table-col" },
                { key: 'action', label: 'Action', class: "action-sticky", stickyColumn: true, isRowHeader: true },
            ],
        };
    },
    methods: {
        formattedDate(date) {
            return date ? dayjs(date).format('MM/DD/YYYY hh:mmA') : '';
        },
        chooseFile() {
            if (this.$refs.upload != undefined) {
                this.$refs.upload.click();
            }
        },
        changeSorting() {
            this.feedItemList = this.feedItemList.slice().reverse();
            this.resetFeedItem();
        },
        async getRssDetails() {
            try {
                let response = await this.$api.get(rssFeeds.getRssFeed(this.$route?.query?.id));

                delete response?.data?.info?.rss_content;

                if (response.data?.info?.feeditems?.length) {

                    this.feedItemList = response.data?.info?.feeditems;

                    this.feedItemList.forEach(async feedItem => {
                        if (feedItem.image != '') {
                            let fileId;

                            if (feedItem.fileId) {
                                fileId = feedItem.fileId;
                            }
                            else {
                                fileId = feedItem.image.match(/files\/([^?]+)\.(?:jpg|png|webp)/)[0];
                            }

                            if (fileId) {
                                let fileurl = await this.geturl(fileId);
                                feedItem.image = fileurl;
                                feedItem.fileId = fileId;
                            }
                        }
                    });
                }

                this.initialFeedDetails = {
                    ...response.data.info,
                    feeditems: [...this.feedItemList],
                };

                this.rssFeedDetails = {
                    ...response.data.info,
                    feeditems: [...this.feedItemList],
                    sorting: response.data.info?.sorting == 'NEWEST' ? 0 : 1,
                };

                this.isEditable = true;

            } catch (err) {
                this.$toasted.error('Failed to fetch details')
            }
        },
        async uploadFile(event, type) {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                const fileName = file.name;
                const fileSize = file.size;
                let fileNameSplit = fileName.split('.');
                let fileExtension = fileNameSplit[1];

                if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                    this.$toasted.error('Only png, jpg/jpeg files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (fileSize / 1024 / 1024 > 10) {
                    this.$toasted.error('Image size should be less than 10MB');
                    this.$refs.upload.value = '';
                    return;
                }

                //HTTP trigger for File upload starts
                try {
                    this.showImgLoader = true;
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('type', 'rss_image');

                    const endpoint = dashboardBuilder.uploadAssets();
                    let response = await this.$api.post(endpoint, formData);

                    if (response.data.success) {
                        let res = await this.geturl(response.data.s3_link);
                        if (type == 'RssImage') {
                            this.rssFeedDetails.feedimage = response.data.s3_link;
                        }
                        else {
                            this.feedItem.image = res;
                            this.feedItem.fileId = response.data.s3_link;
                        }
                    }
                } catch (e) {
                    this.$toasted.error('Failed to upload image');
                    this.$refs.upload.value = '';
                } finally {
                    this.showImgLoader = false;
                }
            }
        },
        async saveRecord() {
            try {
                if (this.rssFeedDetails.feedtitle == '') {
                    window.scrollTo(0, 0);
                    return this.$toasted.error('Feed title is required field')
                }
                if (!this.feedItemList?.length) {
                    return this.$toasted.error('Please add atleast one feed item')
                }
                let response;
                let endpoint = rssFeeds.checkNameExists();
                response = await this.$api.post(endpoint,{
                    RSSName : this.rssFeedDetails.feedtitle,
                    id : this.$route?.query?.id
                });
                if (!response.data.success) {
                    return this.$toasted.error(response?.data?.error); 
                }
                this.rssFeedDetails = {
                    ...this.rssFeedDetails,
                    feeditems: this.feedItemList,
                    sorting: this.rssFeedDetails.sorting ? 'OLDEST' : 'NEWEST',
                }

                if (!this.isEditable) {
                    let endpoint = rssFeeds.createRssFeed();
                    response = await this.$api.post(endpoint, this.rssFeedDetails);
                }
                else {
                    let endpoint = rssFeeds.updateRssFeed(this.$route?.query?.id);
                    response = await this.$api.put(endpoint, this.rssFeedDetails);
                }

                if (response.data.success) {
                    this.$toasted.success(`Feed data ${this.isEditable ? 'updated' : 'added'} successfully`)

                    if (this.isEditable) {

                        let isUpdated = JSON.stringify(this.initialFeedDetails) !== JSON.stringify(this.rssFeedDetails);

                        if (isUpdated) {
                            await this.sendRssBuildUpdatedNotification();
                        }
                    }

                    await this.$router.replace({ name: 'RSSBuilderList', replace: true });
                }
                else {
                    this.$toasted.error('Failed to save record')
                }
            } catch (err) {
                this.$toasted.error('Failed to save record')
            }
        },
        async sendRssBuildUpdatedNotification() {
            try {
                let endpoint = rssFeeds.getListofUser(this.$route?.query?.id);
                let res = await this.$api.get(endpoint);
                if (res?.data?.info?.length) {
                    await rssBuildNotification(res.data.info, this.$route?.query?.id);
                }
            }
            catch (err) {
                this.$toasted.error("Failed to send notifications to members");
            }
        },
        async saveRssItem() {
            const { link, title, description } = this.feedItem;
            if (link == '' || title == '' || description == '') {
                return this.$toasted.error('Please fill all required fields');
            }
            if (!this.isFeedEditable) {
                let currentDate = new Date;
                this.feedItem.id = Date.now();
                this.feedItem.pubDate = Date.now();
                this.feedItem.publicationDate = currentDate.toISOString();
                if (!this.rssFeedDetails.sorting) {
                    this.feedItemList.unshift({ ...this.feedItem });
                }
                else {
                    this.feedItemList.push({ ...this.feedItem });
                }
            }
            else {
                this.feedItemList.forEach((item, index, dummy) => {
                    if (item.id == this.feedItem.id) {
                        dummy[index] = this.feedItem;
                    }
                })
            }
            this.resetFeedItem();
        },
        async geturl(fileId) {
            const endpoint = dashboardBuilder.getUrls();
            const requestData = {
                fileIds: [fileId],
            };
            let response = await this.$api.post(endpoint, requestData);
            return response?.data?.data[0].image;
        },
        showDeleteAlertPopUp(item) {
            this.showDeleteAlert = true;
            this.feedItemIndex = item.id;
        },
        handleDelete() {
            this.feedItemList = this.feedItemList.filter(item => item.id != this.feedItemIndex);
            this.showDeleteAlert = false;
        },
        resetFeedItem() {
            this.isFeedEditable = false;
            this.feedItemIndex = null;
            this.feedItem = { ...initialFeedItem };
        },
        editFeedItem(item) {
            window.scrollTo(0, 0);
            this.isFeedEditable = true;
            this.feedItem = { ...item };
        },
        async cancelEdit() {
            await this.$router.replace({ name: 'RSSBuilderList', replace: true });
            this.isEditable = false;
        },
    },
    async created() {
        if (this.$route?.query?.id) {
            await this.getRssDetails();
        }
        this.loading = false;
    },

}
</script>

<style scoped>
.save-btn {
    background: #5057C3;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: white;
    width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
}

.btn-cancel,
.btn-cancel:hover {
    border-radius: 10px;
    border: 2px solid #AC2734;
}

.rss-list-table table tbody tr>td:first-child {
    padding-left: 2.5em !important;
}

.b-table-sticky-column:last-child {
    right: 0;
    min-width: 100px;
}

.action-sticky {
    min-width: 150px;
    box-shadow: -10px 0px 10px 1px #aaaaaa;
}

.table-col {
    max-width: 150px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.table-container {
    border-radius: 27px;
    border: 1px solid #ebeff4;
}

.table-header .text-button {
    background-color: #5057C3;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

form {
    box-shadow: none;
}
</style>